<template>
<div id="personal">
  <div class="personalWrap">
    <div class="personal-left">
      <div class="filter-box">
        <div class="search-box">
          <el-input v-model="conditionForm.searchValue" class="searchCt" placeholder="搜索职位、公司"></el-input>
          <div class="search-icon" @click="getPositionList">
            搜索
          </div>
        </div>
        <div class="address">
          <span>工作地点：</span>
          <label v-for="(item,index) in areaList" :key="index" @click="toggleArea(item.areaCode,index)" :class="{activeSub:areaSub==index}">
            {{item.areaName}}
          </label>
        </div>
        <div class="filter-ct">
          <el-select @change="getPositionList" clearable v-model="conditionForm.workExperience" placeholder="工作经验">
            <el-option v-for="item in workList" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue">
            </el-option>
          </el-select>
          <el-select @change="getPositionList" clearable v-model="conditionForm.minimumEducation" placeholder="学历要求">
            <el-option v-for="item in backgroundList" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue">
            </el-option>
          </el-select>
          <el-select @change="getPositionList" clearable v-model="conditionForm.enterpriseNature" placeholder="企业性质">
            <el-option v-for="item in natureList" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue">
            </el-option>
          </el-select>
          <el-select @change="getPositionList" clearable v-model="conditionForm.scale" placeholder="企业规模">
            <el-option v-for="item in scaleList" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue">
            </el-option>
          </el-select>
          <div class="reset-btn" @click="reset">重置</div>
        </div>
      </div>
      <div v-if="jobList.length>0" class="position-box"  v-loading="loadPosition">
        <div class="position-item" @click="jumpPosition(item)" v-for="(item,index) in jobList" :key="index">
          <div class="ps-left">
            <h4>
              {{item.positionName}}
              <em>
                {{item.minimumWage}}<td v-show="item.maximumSalary">-{{item.maximumSalary}}</td>
              </em>
            </h4>
            <p>
              <label v-show="item.areaName"><img style="width:14px" src="@/assets/imgs/location-icon.png">{{item.areaName}}</label>
              <label><img  src="@/assets/imgs/edu-icon.png">{{item.minimumEducationName}}</label>
              <label><img  src="@/assets/imgs/time-icon.png">{{item.workExperienceName}}</label>
            </p>
          </div>
          <div class="ps-right">
            <img :src="item.enterpriseLogo" />
            <p>
              <span>{{item.enterpriseName}}</span>
              <label>{{item.enterpriseNatureName}} | {{item.companyIndustryName}} | {{item.scaleName}}</label>
            </p>
          </div>
          <div class="ps-bottom">
             <div class="hr">
              <img :src="item.headSculpture">
              <span>{{item.publisherName}} · {{item.position}}</span>
            </div>
            <div class="welfare">
              <span v-show="key<6" v-for="(w,key) in item.benefitsMap" :key="key">{{w}}</span>
            </div>
          </div>
        </div>
      </div>
      <el-empty v-else :image-size="200" description="暂时没有符合该搜索条件的职位"></el-empty>
     <div class="pagination">
        <el-pagination
          @size-change="sizeChange"
          @current-change="currentChange"
          :current-page="conditionForm.currentPage"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="conditionForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <div class="personal-right">
      <div class="user-sider">
        <img class="avator" :src="userInfo.headSculpture">
        <div class="u-name">
          <h5>{{userInfo.userName}}</h5>
          <p>
            <img src="@/assets/imgs/personal/phoneIcon.png" /><span>{{userInfo.phone}}</span>
          </p>
          <p>
            <router-link :to="{path:'/setting'}"><img src="@/assets/imgs/personal/setIcon.png" /><label>账户设置</label></router-link>
          </p>
        </div>
        <div class="user-stat">
          <router-link :to="{path:'/chat'}">
            <p>
              <span>{{personalNum.communicateNum}}</span>
              <label>沟通过</label>
            </p>
          </router-link>
          <p @click="jumpDelivery">
            <span>{{personalNum.personalDeliveryNum}}</span>
            <label>已投递</label>
          </p>
          <p @click="jumpInterview">
            <span>{{personalNum.interviewNum}}</span>
            <label>面试</label>
          </p>
          <p @click="jumpCollect">
            <span>{{personalNum.collectionPositionNum}}</span>
            <label>收藏</label>
          </p>
        </div>
      </div>
      <div class="resume-attachment">
        <h4>
           <span>附件简历</span><em>最多上传1份</em>
           <el-upload class="attachment" :on-success="attachmentSuccess" :action="baseUrl" :show-file-list="false">
              <label>附件上传</label>
           </el-upload>
        </h4>
        <div class="file-box" v-show="fileInfo.attachmentResumeUrl">
          <img class="file-icon" src="@/assets/imgs/personal/file-pdf.png">
          <span>{{fileInfo.resumeName}}</span>
          <img class="delBtn" @click="delAttachmentResume" src="@/assets/imgs/personal/delBtn.png">
        </div>
        <div class="noAttachment" v-show="!fileInfo.attachmentResumeUrl">暂无附件简历</div>
        <p v-show="fileInfo.attachmentResumeUrl">更新时间：{{fileInfo.resumeUpdateTime}}</p>
      </div>
      <div class="resume-online">
        <h4><span>在线简历</span>
          <router-link :to="{path:'/resume'}"><label>编辑</label></router-link>
        </h4>
      </div>
      <router-link :to="{path:'/shop'}">
        <div class="resume-online">
          <h4><span>道具商城</span>
            <label>查看</label>
          </h4>
        </div>
      </router-link>
      <div class="footmark">
        <ul>
          <li @click="jumpCollect">
            <span>收藏的职位</span><img src="@/assets/imgs/personal/rightIcon.png" />
          </li>
          <li @click="jumpFollow">
            <span>关注的公司</span><img src="@/assets/imgs/personal/rightIcon.png" /><label></label>
          </li>
          <li @click="jumpFooter">
            <span>浏览足迹</span><img src="@/assets/imgs/personal/rightIcon.png" />
          </li>
           <li @click="jumpSeen">
            <span>谁看过我</span><img src="@/assets/imgs/personal/rightIcon.png" />
          </li>
          <li @click="jumpShield">
            <span>屏蔽公司</span><img src="@/assets/imgs/personal/rightIcon.png" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {getUserPersonalCenter,getResumeCompletionRate, getJobApplicantPositionList,delAttachmentResume,getAttachmentResume,attachmentResume, getAreaList, getDictData,getResumeInfo } from "@/api/index.js";
export default {
  name: "personal",
  data() {
    return {
      jobList: [],
      userInfo: {},
      conditionForm: {
        position: '',
        searchValue: '',
        region: '',
        workExperience: '',
        enterpriseNature: '',
        minimumEducation: '',
        scale: '',
        pageSize: 10,
        currentPage: 1,
      },
      areaSub: 0,
      areaList: [],
      workList: [],
      natureList: [],
      backgroundList: [],
      scaleList: [],
      total: 0,
      loadPosition:true,
      completionDegree:0,
      resumeInfo:{recruitUserInfo:{}},
      fileInfo:{},
      baseUrl: '',
      personalNum:{}
    }
  },
  mounted() {
    this.getAreaList()
    this.getDictData('work_experience')
    this.getDictData('enterprise_nature')
    this.getDictData('background_type')
    this.getDictData('scale_enterprises')
    this.getPositionList()
    this.getResumeInfo()
    this.getAttachmentResume()
    this.getUserPersonalCenter()
    this.getResumeCompletionRate()
    this.baseUrl = process.env.VUE_APP_BASE_API + '/webApi/common/upload'
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
  },
  methods: {
    jumpDelivery(){
       this.$router.push({
        path:'/setting',
        query:{
          type:6
        }
      })  
    },
    jumpSeen(){
      this.$router.push({
        path:'/setting',
        query:{
          type:4
        }
      })  
    },
    jumpInterview(){
       this.$router.push({
        path:'/setting',
        query:{
          type:7
        }
      })  
    },
    jumpShield(){
       this.$router.push({
        path:'/setting',
        query:{
          type:8
        }
      })  
    },
    getUserPersonalCenter(){
      getUserPersonalCenter().then(res=>{
        this.personalNum= res.data
      })
    },
    getAttachmentResume(){
      getAttachmentResume().then(res=>{
        this.fileInfo = res.data
      })
    },
    delAttachmentResume(){
      delAttachmentResume().then(res=>{
        if(res.code==200){
          this.$message({
            message: '删除成功！',
            type: 'success'
          })
          this.getAttachmentResume()
        }
      })
    },
    attachmentSuccess(res) {
      attachmentResume({
        attachmentResumeUrl:res.url,
        resumeName:res.originalFilename
      }).then(res=>{
         if(res.code==200){
           this.$message({
            message: '附件上传成功！',
            type: 'success'
           })
           this.getAttachmentResume()
         }
      })
    },
    jumpCollect(){
      this.$router.push({
        path:'/setting',
        query:{
          type:1
        }
      })  
    },
    jumpFollow(){
      this.$router.push({
        path:'/setting',
        query:{
          type:2
        }
      })  
    },
    jumpFooter(){
      this.$router.push({
        path:'/setting',
        query:{
          type:3
        }
      })  
    },
    getResumeInfo(){
       getResumeInfo({
         userInfoId:JSON.parse(localStorage.getItem('userInfo')).id
      }).then(res => {
        var info = res.data
        this.resumeInfo = info
      })
    },
    getResumeCompletionRate(){
       getResumeCompletionRate().then(res => {
        this.completionDegree = res.data.completionDegree
      })
    },
    sizeChange(val) {
      this.conditionForm.pageSize = val
      this.getPositionList()
    },
    currentChange(val) {
      this.conditionForm.currentPage = val
      this.getPositionList()
    },
    getDictData(dictType) {
      getDictData({
        dictType: dictType
      }).then(res => {
        if (dictType == 'work_experience') {
          this.workList = res.rows
        }
        if (dictType == 'enterprise_nature') {
          this.natureList = res.rows
        }
        if (dictType == 'background_type') {
          this.backgroundList = res.rows
        }
        if (dictType == 'scale_enterprises') {
          this.scaleList = res.rows
        }
      })
    },
    reset() {
      this.conditionForm.workExperience = ''
      this.conditionForm.enterpriseNature = ''
      this.conditionForm.minimumEducation = ''
      this.conditionForm.scale = ''
      this.getPositionList()
    },
    toggleArea(areaCode, index) {
      this.conditionForm.region = areaCode
      this.areaSub = index
      this.getPositionList()
    },
    getAreaList() {
      getAreaList().then(res => {
        this.areaList = res.rows
      })
    },
    jumpPosition(item) {
      this.$router.push({
        path: '/jobDetail',
        query: {
          positionId: item.id
        }
      })
    },
    getPositionList() {
       this.loadPosition = true
      getJobApplicantPositionList(this.conditionForm).then(res => {
        this.jobList = res.rows
        this.total = res.total
        var benefitsList = JSON.parse(localStorage.getItem('benefitsList'))
         this.loadPosition = false
        this.jobList.forEach(item => {
          if (item.materialBenefits) {
            var benefits = item.materialBenefits.split(',')
            item.benefitsMap = []
            benefits.forEach(g => {
              benefitsList.forEach(s => {
                if (g == s.dictValue) {
                  item.benefitsMap.push(s.dictLabel)
                }
              })
            })
          }
        })
      
      })
    },
  }
};
</script>

<style lang="scss" scoped>
#personal {
  width: 100%;
  min-height: 800px;
  background: linear-gradient(0deg, #F5F5F5 1%, #D6E9FF 100%);
  overflow: hidden;
}

.personalWrap {
  width: 1200px;
  margin: 16px auto;
}

.recommend {
  width: 740px;
  height: 76px;
  background: #FFFFFF;
  padding-top: 25px;
  padding-left: 20px;
  padding-right: 20px;

  h3 {
    font-size: 20px;
    font-weight: 500;
    color: #222222;

    label {
      display: block;
      float: right;
      font-size: 16px;
      color: #0077FF;
      margin-top: 7px;
      cursor: pointer;
    }
  }

  p {
    font-size: 16px;
    color: #222222;
    margin-top: 5px;
  }
}
.pagination{
  margin-top: 20px;
  margin-bottom: 15px;
}
.position-box {
  .position-item {
    width: 744px;
    min-height: 116px;
    margin-top: 13px;
    padding: 18px 20px 25px;
    background: #FFFFFF;
    border-radius: 10px;
    cursor: pointer;
  }

  .ps-left {
    padding-bottom: 15px;
    float: left;
    width: 400px;

    h4 {
      font-size: 18px;
      font-weight: 500;
      color: #222222;

      span {
        display: inline-block;
        width: 41px;
        height: 21px;
        line-height: 21px;
        text-align: center;
        border: 1px solid #FF2400;
        border-radius: 2px;
        font-size: 14px;
        color: #FF2400;
        margin-left: 10px;
      }

      label {
        display: inline-block;
        width: 41px;
        height: 21px;
        border: 1px solid #F99746;
        border-radius: 2px;
        line-height: 21px;
        text-align: center;
        font-size: 14px;
        color: #F99746;
        margin-left: 9px;
        margin-right: 15px;
      }

      em {
        font-style: normal;
        font-size: 18px;
        color: #FF2400;
        margin-left: 10px;
      }
    }

    p {
      margin-top: 13px;

      img {
        position: relative;
        top: 3px;
        width: 18px;
        height: 18px;
        margin-right: 10px;
      }

      label {
        display: inline-block;
        font-size: 14px;
        color: #999999;
        margin-right: 40px;
      }
    }
  }

  .ps-right {
    float: left;

    img {
      width: 61px;
      height: 61px;
      float: left;
      border-radius: 6px;
    }

    span {
      display: block;
      font-size: 18px;
      color: #222222;
      margin-bottom: 6px;
    }

    p {
      width: 270px;
      float: left;
      margin-left: 10px;
      font-size: 14px;
      color: #999999;
      margin-top: 6px;
      margin-bottom: 8px;
    }
  }

  .ps-bottom {
    clear: both;
    border-top: 1px solid #ddd;

    .welfare {
      width: 440px;
      float: left;

      span {
        display: inline-block;
        min-width: 50px;
        padding: 0 5px;
        margin-top: 18px;
        height: 25px;
        text-align: center;
        line-height: 25px;
        background: #EBF1F8;
        color: #0077FF;
        font-size: 14px;
        border-radius: 2px;
        margin-right: 10px;
      }
    }

    .hr {
      float: left;
       min-width: 190px;
      img {
        width: 30px;
        height: 30px;
        position: relative;
        top: 15px;
      }

      label {
        display: inline-block;
        width: 66px;
        height: 27px;
        line-height: 27px;
        text-align: center;
        background: #E6F9EF;
        font-size: 14px;
        color: #07C160;
        border-radius: 2px;
        position: relative;
        top: 7px;
      }

      span {
        display: inline-block;
        margin-left: 11px;
        margin-right: 15px;
        font-size: 16px;
        position: relative;
        top: 7px;
        color: #666666;
      }
    }
  }
}

.personal-left {
  float: left;
}

.personal-right {
  float: left;
  margin-left: 13px;
  width: 402px;
}

.user-sider {
  width: 362px;
  height: 200px;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 25px 20px;

  .avator {
    width: 110px;
    height: 110px;
    float: left;
  }

  .u-name {
    float: left;
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 15px;

    img {
      width: 24px;
      height: 24px;
      position: relative;
      top: 6px;
      margin-right: 10px;
    }

    h5 {
      font-size: 20px;
      font-weight: 500;
      color: #2A2C33;
    }

    p {
      margin-top: 5px;

      span {
        font-size: 16px;
        color: #999999;
         cursor: pointer;
      }

      label {
        font-size: 16px;
        color: #222222;
         cursor: pointer;
      }
    }
  }

  .user-stat {
    clear: both;
    border-top: 1px solid #DDDDDD;
    height: 93px;

    p {
      float: left;
      text-align: center;
      width: 25%;
      padding-top: 17px;
      cursor: pointer;

      span {
        font-size: 20px;
        font-weight: 500;
        color: #222222;
      }
      span:hover{
        color: #0077FF;
      }

      label {
        display: block;
        margin-top: 10px;
        font-size: 16px;
        color: #999999;
        cursor: pointer;
      }
    }

  }
}

.resume-online {
  width: 362px;
  height: 28px;
  background: #FFFFFF;
  border-radius: 10px;
  margin-top: 13px;
  padding: 25px 20px;

  h4 {
    margin-bottom: 27px;

    span {
      font-size: 20px;
      font-weight: 500;
      color: #222222;
    }

    label {
      display: block;
      float: right;
      font-size: 16px;
      font-weight: 400;
      color: #666666;
      margin-top: 4px;
      cursor: pointer;
    }
  }
}

.resume-attachment {
  width: 362px;
  min-height: 70px;
  background: #FFFFFF;
  border-radius: 10px;
  margin-top: 13px;
  padding: 25px 20px 18px;

  h4 {
    margin-bottom: 27px;

    span {
      font-size: 20px;
      font-weight: 500;
      color: #222222;
    }

    em {
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      color: #999999;
      display: inline-block;
      margin-left: 10px;
    }

    label {
      display: block;
      float: right;
      font-size: 16px;
      font-weight: 400;
      color: #0077FF;
      margin-top: 4px;
      cursor: pointer;
    }
    label:hover{
      color: #1257a6;
    }
  }

  .file-box {
    height: 35px;
    margin-top: 15px;

    .file-icon {
      width: 20px;
      height: 22px;
      margin-right: 15px;
      position: relative;
      bottom:6px;
    }

    .delBtn {
      width: 18px;
      height: 18px;
      float: right;
      cursor: pointer;
    }

    span {
      display: inline-block;
      width: 300px;
      overflow: hidden;
      white-space: nowrap;
      font-size: 16px;
      text-overflow: ellipsis;
      color: #666666;
      position: relative;
      top: -6px
    }
  }
  .noAttachment{
    margin-bottom: 20px;
    font-size: 16px;
    color: #999;
  }
  p {
    font-size: 16px;
    color: #999999;
    border-top: 1px solid #ddd;
    padding-top: 15px;
  }
}

.footmark {
  width: 362px;
  min-height: 75px;
  background: #FFFFFF;
  border-radius: 10px;
  margin-top: 13px;
  padding: 25px 20px 0;
  margin-bottom: 26px;

  li {
    height: 50px;
    cursor: pointer;

    img {
      width: 8px;
      height: 16px;
      float: right;
      position: relative;
      top: 4px;
    }

    span {
      font-size: 18px;
      color: #222222;
    }

    label {
      display: block;
      float: right;
      font-size: 18px;
      color: #666666;
      margin-right: 10px;
    }
  }
}

.filter-box {
  min-height: 70px;
  width: 745px;
  padding: 20px 20px 15px;
  background: #FFFFFF;
  border-radius: 10px;
  margin-bottom: 13px;

  span {
    font-size: 16px;
    color: #222222;
    display: inline-block;
    margin-right: 5px;
  }

  label {
    font-size: 14px;
    display: inline-block;
    margin-right: 20px;
    cursor: pointer;

  }

  .activeSub {
    color: #0077FF;
  }

  .reset-btn {
    width: 100px;
    height: 32px;
    line-height: 32px;
    margin-left: 10px;
    border-radius: 4px;
    text-align: center;
    font-size: 13px;
    color: #fff;
    background: #0077FF;
    display: inline-block;
    cursor: pointer;
  }
}
.attachment{
  display: block;
  float: right;
}
</style><style lang="scss">
.personalWrap {
  .resume-online{
    .el-progress__text{
      font-size: 16px !important;
    }
  }
  .filter-box {
    .search-box {
      width: 740px;
      margin-left: 0;

      .el-input__inner {
        width: 600px;
        border: 1px solid #0077FF;
      }
      .searchCt{
        width: 600px;
      }
      .search-box .search-icon {
        border: 1px solid #0077FF;
      }
    }
  }

}
</style>
